import React, { useEffect, useState, useRef } from 'react'
import Page from '@/components/Page'

// import { SiteMetadataPageQuery } from '../../types.generated'
// import GatsbyTypes from '@/src/__generated__'
import { pageFadeInOut } from '@/modules/animationVariants'
import { Left } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'
import gsap from 'gsap'
import { Box } from '@xstyled/styled-components'
import {
  DarkBox,
  GrayHalfBackground,
  PageContentWrap,
  PageSelectorWrap,
  PageSelector,
  PageSelectorActive,
  PrivacyTitle,
  TableHeader,
  TableWrap,
  TableBody,
  TableBodyRow,
  TableContainer,
} from './styled'
import { Text } from '@/components/Text'
import { SimplifiedPageWrapper } from '@/components/PageWrapper/SimplifiedPageWrapper'

const isServer = typeof window === 'undefined'
const getIsMobile = () =>
  isServer ? false : document.documentElement.clientWidth <= 960

interface PageContextInterface {
  cmsPrivacySingle: GatsbyTypes.CmsPrivacySingle
}

const Index: React.FC<{ pageContext: PageContextInterface }> = ({
  pageContext,
}) => {
  const [isMobile, setIsMobile] = useState(getIsMobile())
  const pageRef = React.useRef(null)
  const { cmsPrivacySingle } = pageContext
  const [offsetTop, setOffsetTop] = useState(false)
  const timelineRef = useRef(null)
  const backgroundBoxRef = useRef(null)
  const contentWrapRef = useRef(null)
  const titleBoxRef = useRef(null)

  /* eslint-disable camelcase */
  const {
    cookie_tab_table_1,
    cookie_tab_table_2,
    cookie_tab_table_3,
  } = cmsPrivacySingle
  /* eslint-enable camelcase */

  const [activeTab, setActiveTab] = useState('privacy')
  const scrollAwayTop = () => {
    if (timelineRef.current) {
      timelineRef.current.kill()
    }
    timelineRef.current = gsap.timeline()

    timelineRef.current.fromTo(
      titleBoxRef.current,
      0.5,
      { y: 0 },
      { y: -100, opacity: 0 },
    )
    timelineRef.current.fromTo(
      backgroundBoxRef.current,
      0.5,
      { y: 0 },
      { y: -100 },
      0,
    )
    timelineRef.current.fromTo(
      contentWrapRef.current,
      0.5,
      { y: 0 },
      { y: -90 },
      0,
    )
  }
  const resetTop = () => {
    if (timelineRef.current) {
      timelineRef.current.kill()
    }
    timelineRef.current = gsap.timeline()
    timelineRef.current.fromTo(
      titleBoxRef.current,
      0.5,
      { y: -100, opacity: 0 },
      { y: 0, opacity: 1 },
    )
    timelineRef.current.fromTo(
      backgroundBoxRef.current,
      0.5,
      { y: -100 },
      { y: 0 },
      0,
    )
    timelineRef.current.fromTo(
      contentWrapRef.current,
      0.5,
      { y: -70 },
      { y: 0 },
      0,
    )
  }
  const onTouchMove = () => {
    if (isMobile) {
      if (document.getElementById('pageContent').scrollTop > 0) {
        setOffsetTop(true)
      } else {
        setOffsetTop(false)
      }
    }
  }
  useEffect(() => {
    if (offsetTop) {
      scrollAwayTop()
    } else {
      resetTop()
    }
  }, [offsetTop])

  const onWheel = () => {
    onTouchMove()
  }
  const addDomEvents = () => {
    document.addEventListener('touchmove', onWheel)
  }

  const removeDomEvents = () => {
    document.removeEventListener('touchmove', onWheel)
  }

  useEffect(() => {
    const onResize = () => {
      const newIsMobile = getIsMobile()
      setIsMobile(newIsMobile)
      if (!newIsMobile) {
        setOffsetTop(false)
      }
    }
    window.addEventListener('resize', onResize)
    addDomEvents()
    gsap.fromTo(
      pageRef.current,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 2, ease: 'Circ.easeOut' },
    )
    return () => {
      window.removeEventListener('resize', onResize)
      removeDomEvents()
      if (timelineRef.current) {
        timelineRef.current.kill()
      }
    }
  }, [])
  const toggleTab = (chosenTab) => {
    if (activeTab !== chosenTab) {
      setActiveTab(chosenTab)
    }
  }

  const renderTable = (tableData) => {
    const { tableHeaders, tableRows } = tableData

    const headerRow = (
      <TableHeader>
        {tableHeaders.map((header) => (
          <span>{header}</span>
        ))}
      </TableHeader>
    )

    const tableBody = (
      <TableBody>
        {tableRows.map((row) => (
          <TableBodyRow>
            {tableHeaders.map((header) => (
              <span>{row[header]}</span>
            ))}
          </TableBodyRow>
        ))}
      </TableBody>
    )

    return (
      <TableContainer>
        {headerRow} {tableBody}
      </TableContainer>
    )
  }

  return (
    <>
      <Left>
        <SideNavigationLink
          variant="light"
          position="left"
          delay={0}
          time={1}
          url="/latest-insights/overview"
          text="back"
        />
      </Left>
      <Page
        content={
          <div ref={pageRef}>
            <GrayHalfBackground ref={backgroundBoxRef} />
            <SimplifiedPageWrapper>
              <DarkBox
                row
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box ref={titleBoxRef} col={{ xs: 1, xl: 0.4 }}>
                  <PrivacyTitle>
                    <Text title="Privacy & cookie policy" />
                  </PrivacyTitle>
                </Box>
                <Box ref={contentWrapRef} col={{ xs: 1, xl: 0.5 }}>
                  <PageSelectorWrap>
                    {activeTab === 'privacy' ? (
                      <PageSelectorActive>
                        {cmsPrivacySingle.privacy_tab_title}
                      </PageSelectorActive>
                    ) : (
                      <PageSelector
                        onClick={() => {
                          toggleTab('privacy')
                        }}
                      >
                        {cmsPrivacySingle.privacy_tab_title}
                      </PageSelector>
                    )}

                    {activeTab === 'cookie' ? (
                      <PageSelectorActive>
                        {cmsPrivacySingle.cookie_tab_title}
                      </PageSelectorActive>
                    ) : (
                      <PageSelector
                        onClick={() => {
                          toggleTab('cookie')
                        }}
                      >
                        {cmsPrivacySingle.cookie_tab_title}
                      </PageSelector>
                    )}
                  </PageSelectorWrap>
                  <PageContentWrap
                    maxHeightModifier={offsetTop ? '280px' : '300px'}
                    id="pageContent"
                  >
                    {activeTab === 'privacy' && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: cmsPrivacySingle.privacy_text.replace(
                            /(?:\r\n|\r|\n)/g,
                            '<br/>',
                          ),
                        }}
                      />
                    )}
                    {activeTab === 'cookie' && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: cmsPrivacySingle.cookie_text.replace(
                              /(?:\r\n|\r|\n)/g,
                              '<br/>',
                            ),
                          }}
                        />
                        <TableWrap>{renderTable(cookie_tab_table_1)}</TableWrap>
                        <TableWrap>{renderTable(cookie_tab_table_2)}</TableWrap>
                        <TableWrap>{renderTable(cookie_tab_table_3)}</TableWrap>
                      </>
                    )}
                  </PageContentWrap>
                </Box>
              </DarkBox>
            </SimplifiedPageWrapper>
          </div>
        }
        settings={{
          backgroundColor: '#ffffff',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default Index
