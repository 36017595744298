import styled, { Box, css } from '@xstyled/styled-components'
import { Background } from '@/components/NewsArticle/styled'
import { breakpoints } from '@xstyled/system'

export const DarkBox = styled(Box)`
  color: secondary;
`
export const PrivacyTitle = styled.div`
  padding-top: 100px;
  ${breakpoints({
    xl: css`
      padding-top: 0;
    `,
  })};
`
export const GrayHalfBackground = styled(Background)`
  /* eslint-disable indent */
  &:before {
    content: '';
    display: block;
    background-color: grayed;
    position: absolute;
    width: 100%;
    height: 240px;
    left: 0;
    top: 0;
  }
  &:after {
    content: '';
    display: block;
    background-color: white;
    position: absolute;
    width: 100%;
    height: 100px;
    left: 0;
    top: 100%;
    z-index: 0;
  }
  padding-bottom: 100px;
  ${breakpoints({
    xl: css`
      &:before {
        width: 50%;
        height: 100%;
        left: 0;
        top: 0;
      }
    `,
  })};
`

export const PageContentWrap = styled.div<{ maxHeightModifier: string }>`
  max-height: ${(props) =>
    `calc(100vh - ${props.maxHeightModifier || '300px'})`};
  overflow-x: hidden;
  padding-bottom: 80px;
  font-size: 16px;
  position: relative;
  ${breakpoints({
    xl: css`
      padding-bottom: 0px;
      padding: 0 80px 0 80px;
    `,
  })};
`

export const PageSelectorWrap = styled.div`
  ${breakpoints({
    xl: css`
      padding: 0 80px 0 80px;
    `,
  })};
  display: flex;
  justify-content: space-between;
`

export const PageSelector = styled.div`
  flex: 1;
  font-family: primary;
  text-transform: uppercase;
  font-size: 12px;
  border-bottom: 1px solid #a3a3a3;
  font-weight: bold;
  color: #a3a3a3;
  padding: 15px 0;
  margin-bottom: 30px;
  cursor: pointer;
  display: block;
`

export const PageSelectorActive = styled(PageSelector)`
  border-bottom: 2px solid #456859;
  color: #456859;
`

export const TableWrap = styled.div`
  overflow: hidden;
  overflow-x: auto;
`

export const TableHeader = styled.div`
  display: flex;
  span {
    text-transform: capitalize;
    border: 1px solid #b2b2b2;
    border-right: none;
    padding: 15px 25px;
    flex-shrink: 0;
    font-weight: bold;
    display: inline-block;
    width: 122px;
    font-size: 14px;
    &:nth-child(3) {
      width: 202px;
    }
    &:last-child {
      border-right: 1px solid #b2b2b2;
    }
  }
`
export const TableBody = styled.div``
export const TableContainer = styled.div`
  margin-bottom: 40px;
`

/* stylelint-disable */
export const TableBodyRow = styled.div`
  display: flex;
  span {
    border: 1px solid #b2b2b2;
    border-right: none;
    word-break: break-word;
    padding: 15px 25px;
    flex-shrink: 0;
    font-weight: bold;
    display: inline-block;
    width: 122px;
    font-size: 12px;
  }
  span:last-child {
    border-right: 1px solid #b2b2b2;
  }
  span:nth-child(3) {
    width: 202px;
  }
`
/* stylelint-disable */
