import styled, { css } from '@xstyled/styled-components'
import { Heading } from '@/components/Heading'
import { variant, breakpoints } from '@xstyled/system'
import ReactPlayer from 'react-player'

export const Panels = styled.div`
  display: flex;
  flex-direction: row;
`

export const Container = styled.div<{ padding: string }>`
  display: flex;
  flex-direction: column;
  color: secondary;
  /* padding: ${(props) => props.padding}; */
  max-height: calc(100vh - 300px);
    padding: 0 32px;
  flex-wrap: nowrap;
  flex: 1;
  overflow: auto;
  ${breakpoints({
    xl: css`
      margin-top: 115px;
      min-height: calc(100vh - 115px);
      max-height: calc(100vh - 115px);
      padding-bottom: 200px;
    `,
  })};
  img {
    max-width: 100%;
    padding-bottom: 50%;
    margin-bottom: -50%;
    object-position: center center;
    object-fit: cover;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: black;
  }
`

export const Column = styled.div`
  /* display: flex; */
  /* flex: 1; */
  /* flex-direction: column; */
`

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
`

/* TODO: Use SEO friendly elements */
export const PreTitle = styled.span`
  display: block;
  font-size: md;
  font-weight: bold;
  margin-bottom: 1.9rem;
  overflow: hidden;
  color: decoration;

  span {
    display: block;
  }
`

export const Title = styled(Heading)`
  text-transform: uppercase;
  word-break: break-word;
  hyphens: auto;
  color: shade;
`

/* TODO: Make dynamic for all elements, change back to p */
export const Paragraph = styled.p`
  display: block;
  font-size: md;
  line-height: 28px;
  margin-bottom: 3rem;
`

export const HTMLArticle = styled.div`
  font-size: lg;
  line-height: 3.1rem;

  p {
    margin-bottom: 3.1rem;
  }
`

export const MobileNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    max-width: 80%;
  }
`

export const Slides = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 50%;
  display: none;

  ${breakpoints({
    md: css`
      display: block;
    `,
  })}
`
export const SlidesText = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 50%;
  display: none;

  ${breakpoints({
    lg: css`
      display: flex;
      align-items: center;
    `,
  })}
`

export const Slide = styled.div<{ backgroundImage: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
`

export const SlideTitle = styled.div`
  transform: scale(0.6);
  padding-top: 100px;
  h1 {
    margin-bottom: 0;
  }
  ${breakpoints({
    xl: css`
      padding-top: 0;
    `,
  })};
`

export const BlurredSlide = styled.div<{ backgroundImage: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  filter: blur(4px);
`

export const PlayerInfoText = styled.p`
  text-transform: uppercase;
  word-break: break-word;
  hyphens: auto;
  color: white;
  padding: 0 32px;
  font-weight: bold;
  font-size: 1.6rem;
  transform: translateY(30px);
`

export const PlayerWrapper = styled(ReactPlayer)`
  ${variant({
    variants: {
      audio: {
        display: 'none',
      },
      video: {},
    },
  })}
`

export const PlayerContainer = styled.div`
  position: relative;
`

export const ControlsWrapper = styled.div`
  ${variant({
    variants: {
      audio: {
        backgroundColor: 'black',
      },
      video: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
  })}
`

export const VideoSlide = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  z-index: 2;
  > div {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.43);
  }
`
