import React, { useRef, useEffect, ReactNode, useState } from 'react'
import PubSub from 'pubsub-js'
import Helmet from 'react-helmet'
import { APP_VIEW_UPDATE } from '@/events'
import { StaticQuery, graphql } from 'gatsby'
import { SimpleContainer } from './styled'
import contentHeight from '@/modules/utils/contentHeight'
import containerWidth from '@/modules/utils/containerWidth'

interface Props {
  children: ReactNode
  scrollable?: boolean
  noPaddingMobile?: boolean
  useScaling?: boolean
  withStyleScrollingHighJack?: boolean
}

const mobileBreakpoint = 960
const isServer = typeof window === 'undefined'
let interval
const query = graphql`
  query AnotherSiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export const SimplifiedPageWrapper: React.FC<Props> = ({
  children,
  scrollable,
}) => {
  const containerRef = useRef(null)
  const [currentMobileView, setCurrentMobileView] = useState(null)
  const makeScrollable = () => {
    if (containerRef.current) {
      containerRef.current.style.maxHeight = `${contentHeight()}px`
      containerRef.current.style.overflow = 'auto'
    }
  }

  const onResize = () => {
    const mobileView = document.documentElement.clientWidth <= mobileBreakpoint
    if (!isServer && containerRef.current) {
      containerRef.current.style.maxWidth = `${containerWidth()}px`
    }
    const eventData = mobileView ? 'mobile' : 'desktop'
    if (!mobileView || mobileView !== currentMobileView) {
      PubSub.publish(APP_VIEW_UPDATE, eventData)
    }
    setCurrentMobileView(mobileView)
  }

  const addDomEvents = () => {
    window.addEventListener('resize', onResize)
  }

  const removeDomEvents = () => {
    window.removeEventListener('resize', onResize)
    if (interval) {
      clearInterval(interval)
    }
  }

  useEffect(() => {
    const bootstrap = () => {
      if (scrollable) {
        makeScrollable()
      }
    }
    bootstrap()
    addDomEvents()
    onResize()
    return () => {
      removeDomEvents()
    }
  }, [])

  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <>
          <Helmet
            meta={[
              {
                content:
                  'A ruthlessly effective cloud-native core-banking engine',
                name: 'description',
              },
              {
                content: 'banking, cloud-native, core-banking',
                name: 'keywords',
              },
              {
                content: data.site.siteMetadata.title,
                name: 'og:title',
              },
              {
                content:
                  'A ruthlessly effective cloud-native core-banking engine',
                name: 'og:description',
              },
              {
                content: '/images/share-image.jpg',
                name: 'og:image',
              },
              {
                content: '#da532c',
                name: 'msapplication-TileColor',
              },
              {
                content: '/images/favicon/browserconfig.xml',
                name: 'msapplication-config',
              },
              {
                content: '#ffffff',
                name: 'theme-color',
              },
              {
                content: 'width=device-width, initial-scale=0.95',
                name: 'viewport',
              },
            ]}
            link={[
              {
                href: '/fonts.css',
                rel: 'stylesheet',
                type: 'text/css',
              },
              {
                href: '/images/favicon/apple-touch-icon.png',
                rel: 'apple-touch-icon',
                sizes: '180x180',
              },
              {
                href: '/images/favicon/favicon-32x32.png',
                rel: 'icon',
                type: 'image/png',
                sizes: '32x32',
              },
              {
                href: '/images/favicon/favicon-16x16.png',
                rel: 'icon',
                type: 'image/png',
                sizes: '16x16',
              },
              {
                href: '/images/favicon/site.webmanifest',
                rel: 'manifest',
              },
              {
                href: '/images/favicon/safari-pinned-tab.svg',
                rel: 'mask-icon',
                color: '#5bbad5',
              },
              {
                href: '/images/favicon/favicon.ico',
                rel: 'shortcut icon',
              },
            ]}
          >
            <html lang="en" />
          </Helmet>

          <SimpleContainer ref={containerRef}>{children}</SimpleContainer>
        </>
      )}
    />
  )
}
